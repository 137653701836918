<template>
  <div class="home">
    <!-- eslint-disable -->
    <div class="legal-holder">
      <h1>Terms of Service</h1>
      <p>Tuumik Systems OÜ (<strong>"Provider"</strong>), a company registered in Estonia (registry code 14387706, address Värsi tn 14a, 10919 Tallinn, Estonia), operates a team management application that is made available to clients as Software as a Service via single-tenant or multi-tenant deployments in various configurations (<strong>"Service"</strong>). This software is described in more detail at www.tuumik.com. These Terms of Service (<strong>"Terms"</strong>) govern the use of the Service.</p>
      <p>By registering to use the Service, you are agreeing to be bound by the Terms. If you do not agree with the Terms, do not access or use the Service in any way.</p>
      <p>The Provider's contact data is available at www.tuumik.com.</p>
      <h2>1. Client and Users</h2>
      <p>1.1 Registration for the Service requires specifying an organization (typically a company). This organization shall be considered as the entity that signed up for the Service (<strong>"Client"</strong>). By accepting the Terms on behalf of a Client, the person performing the registration represents that it has the legal capacity to do so.</p>
      <p>1.2 The Client shall use the Service via user accounts (<strong>"User Account"</strong>). User Accounts enable specific individuals to access the Service on behalf of the Client. It is the Client's responsibility to ensure that only authorized persons are granted access to its User Accounts. The Client shall be liable for any actions performed by users of its User Accounts.</p>
      <p>1.3 The Service is offered only to legal entities and individuals acting within their trade or business activities. The Service is not offered to consumers.</p>
      <p>1.4 Registration for the Service requires specifying an email address and a telephone number as the Client's contact data. This contact data can be later modified by the Client at any time inside the software platform. The Client must keep this contact data updated at all times.</p>
      <h2>2. Fees and Payment</h2>
      <p>2.1 For use of the Service the Client shall pay to the Provider a monthly subscription fee. The amount of such fee shall depend on the subscription plan and user count selected by the Client. Prices for different plans are described at www.tuumik.com.</p>
      <p>2.2 Payments are non-refundable. There are no refunds or credits for partial use of the Service.</p>
      <p>2.3 A valid credit card is required for payment of fees.</p>
      <p>2.4 All fees are exclusive of any taxes, levies or duties imposed by taxing authorities, except when otherwise required by mandatory law. Client is solely responsible for the payment of such taxes, levies or duties.</p>
      <h2>3. License</h2>
      <p>3.1 Subject to the Terms, and the payment of applicable fees, the Provider grants the Client and its authorized users a non-exclusive, non-transferable, non-sub-licensable license to use the Service during the period that the Client subscribes to the Service.</p>
      <p>3.2 All intellectual property related to the Service shall belong to, and is retained by, the Provider. The Client shall not acquire any rights thereto in connection with the Service, except for the limited rights of use expressly set forth in the Terms.</p>
      <h2>4. Changes</h2>
      <p>4.1 The Provider may, at its sole discretion, change the Terms from time to time. When doing so, the Provider shall update the Terms at www.tuumik.com/legal/terms-of-service and shall notify the Client thereof via email. Continued use of the Service after any such change shall constitute the Client's agreement with the change. Such amended Terms will automatically be effective upon the earlier of (a) the Client's continued use of the Service, or (b) 30 days from posting of such modified Terms at www.tuumik.com/legal/terms-of-service.</p>
      <p>4.2 The Provider may modify the Service or any part thereof at any time and for any reason, with or without notice.</p>
      <p>4.3 The Provider may modify the structure or prices of any fees connected with the Service at any time and for any reason by giving 30 days of notice.</p>
      <p>4.4 If a Client does not agree with any changes to the Terms, the Service or any fees, then its sole remedy shall be to terminate its use of the Service.</p>
      <h2>5. Acceptable Use</h2>
      <p>5.1 Each User Account may only be used by one person. A single User Account may not be shared by multiple people.</p>
      <p>5.2 The Service may not be used for any illegal purpose.</p>
      <p>5.3 Any automated use of the Service by computer systems is not permitted, except if such use takes place via an API created by the Provider itself specifically for such purpose and in accordance with the technical restrictions stipulated for the API.</p>
      <h2>6. Infrastructure</h2>
      <p>6.1 To provide the Service, the Provider uses technical infrastructure, both hardware and software, managed by third parties that specialize in such services.</p>
      <h2>7. Termination and Suspension</h2>
      <p>7.1 The Client may terminate its use of the Service at any time.</p>
      <p>7.2 If the Client is in breach of the Terms, then the Provider may terminate or suspend the Client's use of the Service.</p>
      <p>7.3 Upon termination of the Client's use of the Service all of the Client's data and User Accounts shall be deleted. This is irreversible and cannot be recovered.</p>
      <h2>8. No Warranty</h2>
      <p>8.1 The Service is provided "as is" and "as available" without warranties of any kind either express or implied. To the fullest extent permissible pursuant to applicable law, the Provider disclaims all warranties, statutory, express or implied, including, but not limited to, implied warranties of merchant ability, fitness for a particular purpose, non-infringement of proprietary rights, correctness, accuracy, and reliability.</p>
      <p>8.2 The Provider in particular makes no representation and gives no warranty or guarantee: (a) that the Service is fit for any particular purpose, accurate, timely, of satisfactory quality, available regardless of, or in any specific, jurisdiction, or non-infringing of third-party rights; (b) that access to or the operation or use of the Service will be uninterrupted, secure or error-free; (c) that any error or defect in the Service will be corrected; (d) that the Service or any means by which it is accessed or used is free of malware or other harmful components.</p>
      <h2>9. Limitation of Liability</h2>
      <p>9.1 The Provider shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, resulting from: (a) the use of or the inability to use the Service; (b) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Service; (c) unauthorized access to or alteration of Client's transmissions or data; (d) statements or conduct of any third party on the Service; (e) or any other matter relating to the Service.</p>
      <p>9.2 In no event shall the aggregate liability of the Provider arising out of or related to the Terms exceed the total amount paid by the Client hereunder for the Service giving rise to the liability in the six months preceding the first incident out of which the liability arose.</p>
      <h2>10. Personal Data</h2>
      <p>10.1 The Provider's Privacy Policy, available at www.tuumik.com/legal/privacy-policy, is hereby incorporated into the Terms by reference. The Privacy Policy governs how the Provider processes any personal data in connection with the Service.</p>
      <h2>11. Governing Law and Jurisdiction</h2>
      <p>11.1 The Terms shall be governed by the laws of the Republic of Estonia.</p>
      <p>11.2 Any dispute arising out of the Terms shall be resolved in Harju County Court in Estonia.</p>
      <p class="update-date">Last update: October 18, 2023</p>
    </div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
  mounted() {
    document.body.scrollIntoView({ behavior: 'instant' });
  },
};
</script>

<style scoped>
.home {
  padding: 3em 0 1em 0;
}

.legal-holder {
  margin: 6em 0;
  padding: 1em;
  text-align: justify;
  background-color: #ffffff;
  color: #000000;
  border-radius: 0.3em;
  border: 1px solid #cecece;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
}

.legal-holder h1 {
  font-size: 2.1em;
  text-align: center;
  margin: 1em 0 2em 0;
}

.legal-holder h2 {
  font-weight: 600;
  font-size: 1.2em;
  margin: 1em 0;
}

.update-date {
  color: #7f7f7f;
  margin: 3em 0 0 0;
}

@media only screen and (min-width: 768px) {
  .legal-holder {
    margin: 6em auto;
    padding: 2em 3em;
    max-width: 50em;
  }
}
</style>
